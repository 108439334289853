import axios from "axios";
import { get, rearg } from "lodash";
import { commonAxios } from "../global/CommonAxios";
import { setAlert } from "./alert";

export const addStory = (token, data, setLoader, history, handleClose) => (dispatch) => {
    setLoader(true)
    commonAxios("create-story", data, dispatch, token)
        .then((res) => {
            if (res.status) {
                history.push(`/create-story?id=${res.data}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
            handleClose()
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
            handleClose()
        })
}

export const fetchStoriesAll = (token, setStories, setLoader, showRecord, setTotalStory) => (dispatch) => {
    commonAxios("fetch-story", {}, dispatch, token)
        .then((res) => {
            if (res.status) {
                let val = res.data
                dispatch({ type: "ADD_TOTAL_STORY", payload: res.data.length })
                if (showRecord) {
                    val = val.slice(0, showRecord)
                    setStories(val)
                } else {
                    setStories(val)
                }
            } else {
                if (res.msg === "Not found course") {
                    setStories([])
                }
            }
            setLoader(false)
        }).catch((error) => {
            console.log(error)
            setLoader(false)
        })
}

export const fetchStory = (id, token, setLoader) => (dispatch, getState) => {
    let data = {
        id: id
    }
    commonAxios("get-story", data, dispatch, token)
        .then((res) => {
            if (res.status) {
                let obj = { ...res.data }
                if (!obj.logo) {
                    obj.logo = {
                        enable: false,
                        logoId: "",
                        pos: "top-left"
                    }
                }
                if (!obj.watermark) {
                    obj.watermark = {
                        enable: false,
                        pos: "top-left",
                        watermarkId: ""
                    }
                }


                if (res.data.slides.length === 0) {
                    let dataVal = {
                        storyId: id
                    }
                    commonAxios("create-slide", dataVal, dispatch, token)
                        .then((res) => {
                            if (res.status) {
                                let slide = {
                                    id: res.data,
                                    userId: getState().auth.user.id,
                                    storyId: id,
                                    background: {
                                        type: "color",
                                        src: "#ffffff",
                                        style: {
                                            color: "",
                                            width: "",
                                            height: "",
                                            fontSize: "",
                                            fontWeight: "",
                                            fontFamily: "",
                                            lineHeight: "",
                                            textTransform: "",
                                            textDecoration: "",
                                            backgroundColor: "",
                                            textAlign: "",
                                            opacity: ""
                                        }
                                    },
                                    templateId: null,
                                    audio: {},
                                    animation: {
                                        name: "",
                                        delay: 0,
                                        duration: 0
                                    },
                                    duration: 5,
                                    status: 0,
                                    isSelected: true,
                                    created: "",
                                    modified: "",
                                    playAnimation: false,
                                    layers: [],
                                    templateType: "inbuilt"
                                }
                                obj.slides[0] = slide
                                obj.undoRedo = []
                                obj.slide_rank = [{
                                    id: res.data
                                }]
                                dispatch({ type: "ADD_STORY_DATA", payload: obj })
                                setLoader(false)
                            }
                        }).catch((err) => {
                            console.log(err)
                        })
                }
                else {
                    obj.lastAction = "ADD_STORY_DATA"

                    if (obj.logo !== null) {
                        obj.logo = JSON.parse(obj.logo)
                    }
                    if (obj.watermark !== null) {
                        obj.watermark = JSON.parse(obj.watermark)
                    }
                    if (obj.bg_audio) {
                        obj.bg_audio = JSON.parse(obj.bg_audio)
                    }
                    obj.undoRedo = []
                    obj.slides.forEach((curElem, index) => {
                        obj.undoRedo.push({
                            slideId: curElem.id,
                            past: [],
                            present: curElem,
                            future: []
                        })
                        if (curElem.layers === "") {
                            curElem.layers = []
                        } else if (curElem.layers.length > 0) {
                            curElem.layers.forEach((layer) => {
                                layer.animation = {
                                    ...layer.animation,
                                    playAnimation: false
                                }
                            })
                        }
                    })
                    let newSlideArr = []
                    if (obj.slide_rank !== "") {
                        let slideRank = JSON.parse((obj.slide_rank))
                        if (slideRank.length > 0) {
                            slideRank.forEach((curElem) => {
                                const slide = obj.slides.find(({ id }) => parseInt(id) === parseInt(curElem.id))
                                if (slide) {
                                    newSlideArr.push(slide)
                                }
                            })
                            obj.slides = newSlideArr
                        }
                        obj.slide_rank = slideRank
                    }
                    dispatch({ type: "ADD_STORY_DATA", payload: obj })
                    setLoader(false)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onDeleteStory = (data, token, getStories, setButton, setShowDelete) => (dispatch) => {
    commonAxios("del-story", data, dispatch, token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                getStories()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setButton("Delete")
            setShowDelete(false)

        }).catch((error) => {
            dispatch(setAlert(error.message, "danger"))
            setButton("Delete")
            setShowDelete(false)
            console.log(error)
        })
}

export const onUnmountStory = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_STORY", payload: false })
}

export const updateName = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_NAME", payload: data })
}

export const updateSlideChange = (index, type) => (dispatch) => {
    if (type === "pre") {
        dispatch({ type: "SLIDE_BACK", payload: index })
    } else {
        dispatch({ type: "SLIDE_FORE", payload: index })
    }

}



export const addSlide = (index, type, selectedSlide, setLoader, loader) => (dispatch, getState) => {
    let dataVal = {
        storyId: selectedSlide.storyId
    }
    let token = getState().auth.token
    commonAxios("create-slide", dataVal, dispatch, token)
        .then((res) => {
            if (res.status) {
                let slide = {
                    id: res.data,
                    userId: getState().auth.user.id,
                    storyId: selectedSlide.storyId,
                    background: {
                        type: "color",
                        src: "#ffffff",
                        style: {
                            color: "",
                            width: "",
                            height: "",
                            fontSize: "",
                            fontWeight: "",
                            fontFamily: "",
                            lineHeight: "",
                            textTransform: "",
                            textDecoration: "",
                            backgroundColor: "",
                            textAlign: "",
                            opacity: ""
                        }
                    },
                    templateId: null,
                    audio: {},
                    animation: {
                        name: "",
                        delay: 0,
                        duration: 0
                    },
                    duration: 5,
                    status: 0,
                    isSelected: true,
                    created: "",
                    modified: "",
                    playAnimation: false,
                    layers: [],
                    templateType: "inbuilt"
                }
                const addData = { index, slide, beforeLastId: selectedSlide.id, afterLastId: selectedSlide.id }
                if (type === "before") {
                    dispatch({ type: "SLIDE_ADD_BEFORE", payload: addData })
                    setLoader({
                        ...loader,
                        addBefore: false,
                    })
                } else {
                    dispatch({ type: "SLIDE_ADD_AFTER", payload: addData })
                    setLoader({
                        ...loader,
                        addAfter: false
                    })
                }
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const activeSlide = (index, selectedIndex) => (dispatch) => {
    const data = { index, selectedIndex }
    dispatch({ type: "ACTIVE_SLIDE", payload: data })
}

export const deleteSlide = (index, obj, setLoader, loader) => (dispatch, getState) => {
    let token = getState().auth.token
    let data = {
        slideId: obj.slideId,
        storyId: obj.storyId
    }
    let voooo = { index, slideId: obj.slideId }
    commonAxios("del-slide", data, dispatch, token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "DELETE_SLIDE", payload: voooo })
                setTimeout(() => {
                    setLoader({
                        ...loader,
                        delete: false
                    })
                    dispatch(saveStory(getState().story, token))
                }, 1000)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
        })

}

export const addDuplicteSlide = (slideData, slideIndex, setLoader, loader) => (dispatch, getState) => {
    let newObj = JSON.parse(JSON.stringify(slideData))
    let dataVal = {
        storyId: slideData.storyId
    }
    let token = getState().auth.token
    commonAxios("create-slide", dataVal, dispatch, token)
        .then((res) => {
            if (res.status) {
                newObj = {
                    ...newObj,
                    id: res.data,
                    isSelected: true
                }
                let data = { newObj, slideIndex }
                dispatch({ type: "ADD_DUPLICATE_SLIDE", payload: data })
                setLoader({
                    ...loader,
                    duplicateLoader: false
                })
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const updateBgColor = (color, index) => (dispatch) => {
    const data = { color, index }
    dispatch({ type: "UPDATE_BG_COLOR", payload: data })
}
export const updateBgMedia = (obj, slideIndex) => (dispatch) => {
    const data = { obj, slideIndex }
    dispatch({ type: "UPDATE_BACKGROUND", payload: data })
}

export const updateLayerMedia = (obj, slideIndex, layerIndex) => (dispatch) => {
    const data = { obj, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_MEDIA_LAYER", payload: data })
}



export const addLayerMedia = (obj, slideIndex) => async (dispatch) => {
    let height = 170, width = 190
    if (obj.mediaType === "image") {
        const image = new Image()
        image.src = obj.url
        if (image.height !== 0 && image.width !== 0) {
            height = await image.height
            width = await image.width
        }
        if (width > 360) {
            width = width / 3
            height = height / 3
        }
        else if (height > 640) {
            height = height / 3
            width = width / 3
        }
    }

    let layer = {
        id: parseInt(Math.random() * 10000),
        type: obj.mediaType,
        src: obj.url,
        style: {
            color: "#000",
            fontSize: "25px",
            fontWeight: "bold",
            fontFamily: "",
            lineHeight: "",
            textTransform: "",
            textDecoration: "",
            backgroundColor: "",
            textAlign: "",
            opacity: "",
            border: "",
            objectFit: "cover"
        },
        position: {
            left: 40,
            top: 118
        },
        animation: {
            name: "",
            delay: 0,
            duration: 0
        },
        isSelected: false,
        size: {
            height: obj.mediaType === "video" ? 171 : height,
            width: obj.mediaType === "video" ? 300 : width
        },
        filter: { name: "" }
    }
    if (obj.mediaType === "video" && !obj.url.includes("giphy")) {
        layer = {
            ...layer,
            mute: true
        }
    }


    let data = { layer, slideIndex }
    await dispatch({ type: "ADD_MEDIA_LAYER", payload: data })
}

export const onFileUpload = (data, token, fetchMedia, setLoader, setPercent, type, setImage, setVideo) => (dispatch) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
    }
    setLoader(true)
    commonAxios("upload-file", data, dispatch, token, config)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (type === "video") {
                    fetchMedia(type, setVideo)
                }
                if (type === "images") {
                    fetchMedia(type, setImage)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

// export const fetchMediaData = (type, setMedia, setLoader, token) => (dispatch) => {
//     let data = {
//         type: type
//     }
//     setLoader(true)
//     commonAxios("fetch-user-uploaded-file", data, dispatch, token)
//         .then((res) => {
//             if (res.status) {
//                 setMedia(res.data)
//             }
//             setLoader(false)
//         }).catch((err) => {
//             console.log(err)
//         })
// }

export const updateLayerStatus = (newLayerIndex, slideIndex) => (dispatch) => {
    const data = { newLayerIndex, slideIndex }
    dispatch({ type: "UPDATE_LAYER_STATUS", payload: data })
}

export const deleteLayer = (layerIndex, slideIndex) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    dispatch({ type: "DELETE_LAYER", payload: data })
}


export const updateFontSize = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTSIZE', payload: temp });
}

export const updateFontFamily = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTSTYLE', payload: temp });
}
export const updateTextBold = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTBOLD', payload: temp });
}

export const updateTextItalic = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTITALIC', payload: temp });
}

export const updateTextUpper = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTTRANSFORM', payload: temp });
}

export const updateTextLower = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTTRANSFORM', payload: temp });
}

export const updateTextUnderline = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTDECO', payload: temp });
}

export const updateTextStrike = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTDECO', payload: temp });
}


export const updateTextAlign = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTALIGN', payload: temp });
}

export const updateTextLineHeight = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTLINEHEIGHT', payload: temp });
}

export const updateFontColor = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTCOLOR', payload: temp });
}

export const updateFontBgColor = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTBGCOLOR', payload: temp });
}


export const removeFontBgColor = (selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let data = { selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'REMOVE_TEXT_FONTBGCOLOR', payload: data });
}

export const textLayerAdd = (slideIndex, size, dataIcon, type) => (dispatch) => {
    let borderClass, layer
    if (type === "border") {
        borderClass = `${dataIcon.size}px ${dataIcon.type} ${dataIcon.color}`
    }
    if (borderClass) {
        layer = {
            id: parseInt(Math.random() * 10000),
            type: "border",
            src: "",
            style: {
                color: "#ffffff",
                fontSize: size ? size : 18,
                fontWeight: "",
                fontFamily: "",
                lineHeight: 1,
                textTransform: "",
                textDecoration: "",
                transform: "",
                backgroundColor: "",
                textAlign: "center",
                opacity: "",
                border: borderClass
            },
            position: {
                left: 30,
                top: 40,
            },
            animation: {
                name: "",
                delay: 0,
                duration: 0
            },
            isSelected: true,
            size: {
                height: 60,
                width: 200,
            },
            filter: { name: "" }
        }
    } else {
        layer = {
            id: parseInt(Math.random() * 10000),
            type: type ? type : "text",
            src: dataIcon ? dataIcon : "ADD TEXT HERE",
            style: {
                color: type === "shape" ? "#ffffff" : "#000000",
                fontSize: size ? size : 18,
                fontWeight: "",
                fontFamily: "",
                lineHeight: 1,
                textTransform: "",
                textDecoration: "",
                transform: "",
                backgroundColor: "",
                textAlign: "center",
                opacity: "",
                border: ""
            },
            position: {
                left: 30,
                top: 40,
            },
            animation: {
                name: "",
                delay: 0,
                duration: 0
            },
            isSelected: true,
            size: {
                height: 60,
                width: 200,
            },
            filter: { name: "" }
        }

        if (type === "emoji" || type === "shape" || type === "icon") {
            layer = {
                ...layer,
                size: {
                    ...layer.size,
                    height: 58,
                    width: 63
                },
                style: {
                    ...layer.style,
                    lineHeight: type === "emoji" ? 1.3 : 1
                }
            }
        }
    }



    const data = { layer, slideIndex }
    dispatch({ type: "ADD_TEXT_LAYER", payload: data })
}

export const textLayerUpdate = (text, slideIndex, layerIndex, type) => (dispatch) => {
    let data = { text, slideIndex, layerIndex }
    if (type === "icon") {
        data = { text, slideIndex, layerIndex, type }
    }
    dispatch({ type: "UPDATE_LAYER_TEXT", payload: data })
}

export const borderLayerUpdate = (temp, slideIndex, layerIndex) => (dispatch) => {
    let borderClass = `${temp.size}px ${temp.type} ${temp.color}`
    let data = { borderClass, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_LAYER_BORDER", payload: data })
}
export const addBorder = (border, slideIndex, layerIndex) => (dispatch) => {
    const data = { border, slideIndex, layerIndex }
    dispatch({ type: "ADD_BORDER", payload: data })
}

export const addShadow = (shadowData, slideIndex, layerIndex) => (dispatch) => {
    const data = { shadowData, slideIndex, layerIndex }
    dispatch({ type: "ADD_SHADOW", payload: data })
}
export const duplicateLayer = (layer, slideIndex) => (dispatch) => {
    let newLayer = JSON.parse(JSON.stringify(layer))
    newLayer = {
        ...newLayer,
        position: {
            ...newLayer.position,
            left: parseInt(layer.position.left) + 10 > 300 ? parseInt(layer.position.left) - 5 : parseInt(layer.position.left) + 10,
            top: parseInt(layer.position.top) + 10 > 600 ? parseInt(layer.position.left) - 5 : parseInt(layer.position.top) + 10
        }
    }
    if (newLayer.isSelected) {
        newLayer.isSelected = true
    }
    let data = { newLayer, slideIndex }
    dispatch({ type: "DUPLICATE_LAYER", payload: data })
}
export const layerDown = (layerIndex, slideIndex) => (dispatch) => {
    let data = { layerIndex, slideIndex }
    dispatch({ type: "LAYER_DOWN", payload: data })
}

export const layerUp = (layerIndex, slideIndex) => (dispatch) => {
    let data = { layerIndex, slideIndex }
    dispatch({ type: "LAYER_UP", payload: data })
}

export const resizeLayer = (w, h, l, t, layerI, slideI, type, renderWidth, renderHeight, plusInLeft, plusInRight) => (dispatch) => {
    const data = { w, h, l, t, layerI, slideI, type, renderWidth, renderHeight, plusInLeft, plusInRight }
    dispatch({ type: "RESIZE_LAYER", payload: data })
}
export const updateInlitialWH = (h, w, slideIndex, layerIndex) => (dispatch) => {
    let data = { h, w, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_INITIAL_MEDIA_ATT", payload: data })
}

export const rePositionLayer = (t, l, layerI, slideI) => (dispatch) => {
    const data = { t, l, layerI, slideI }
    dispatch({ type: "REPOSITION_LAYER", payload: data })
}

export const saveStory = (obj, token) => (dispatch) => {
    let data = { ...obj }
    delete data.undoRedo
    commonAxios("saved-slides", data, dispatch, token)
        .then((res) => {
            if (res.status) {
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const updateAnimation = (obj, slideIndex, layerIndex) => (dispatch) => {
    let data = { obj, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_ANIMATION_LAYER", payload: data })
}
export const removeAnimation = (slideIndex, layerIndex) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    dispatch({ type: "REMOVE_ANIMATION", payload: data })
}
export const updateAnimDuration = (duration, slideIndex, layerIndex) => (dispatch) => {
    duration = duration + "s"
    let data = { duration, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_ANIMATION_DURATION", payload: data })
}
export const removeAnimationDuration = (slideIndex, layerIndex) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    dispatch({ type: "REMOVE_ANIMATION_DURATION", payload: data })
}

export const updateAnimDelay = (delay, slideIndex, layerIndex) => (dispatch) => {
    delay = delay + "s"
    let data = { delay, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_ANIMATION_DELAY", payload: data })
}
export const removeAnimDelay = (slideIndex, layerIndex) => (dispatch) => {
    let data = { slideIndex, layerIndex }
    dispatch({ type: "REMOVE_ANIMATION_DELAY", payload: data })
}

export const animationPlay = (slideIndex) => (dispatch) => {
    dispatch({ type: "PLAY_ANIMATION", payload: slideIndex })
}

export const endAnimation = (slideIndex, layerIndex) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    dispatch({ type: "END_ANIMATION", payload: data })
}

export const getTempData = (setData, setLoader, membership) => (dispatch, getState) => {
    commonAxios("load-template", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let temp = res.data.reverse()
                if (membership) {
                    if (membership[membership.length - 1] === "premium") {
                        temp.splice(100, temp.length - 100)
                    }
                }
                setData(temp)
            }
            if (setLoader) {
                setLoader(false)
            }
        }).catch((err) => {
            if (setLoader) {
                setLoader(false)
            }
            console.log(err)
        })
}
export const fetchSavedTempData = () => (dispatch, getState) => {
    commonAxios("load-custom-template", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "FETCH_TEMPLATES", payload: res.data })
            }
        }).catch((err) => {
            console.log(err)
        })
}
export const unselectLayers = (slideIndex) => (dispatch) => {
    dispatch({ type: "UNSELECT_LAYERS", payload: slideIndex })
}

export const addFilter = (filterData, slideIndex, layerIndex) => (dispatch) => {
    let data = { filterData, slideIndex, layerIndex }
    dispatch({ type: "ADD_FILTER", payload: data })
}

export const searchGifData = (data, token, setLoader, setGif) => (dispatch) => {
    commonAxios("load-gif", data, dispatch, token)
        .then((res) => {
            if (res.status) {
                setGif(JSON.parse(res.data).data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}

export const searchPatternData = (token, setPattern) => (dispatch) => {
    let data = {
        types: "pattern"
    }
    commonAxios("load-element", data, dispatch, token)
        .then((res) => {
            if (res.status) {
                setPattern(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}


// undo redo Actions

export const updateTemplate = (tempData, slideIndex, type) => (dispatch) => {
    const data = { tempData, slideIndex, type }
    dispatch({ type: "USE_INBUILT_TEMP", payload: data })
}

export const saveTemplate = (slideData, name, state, setState, handleClose) => (dispatch, getState) => {
    let obj = {
        background: slideData.background,
        layers: slideData.layers
    }
    let objData = {
        data: JSON.stringify(obj),
        name: name,
        preview: "",
        thumbnail: ""
    }
    commonAxios("save-custom-template", objData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                dispatch(fetchSavedTempData())
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setState({
                ...state,
                saveLoader: false
            })
            handleClose()
        }).catch((err) => {
            setState({
                ...state,
                saveLoader: false
            })
            handleClose()
            console.log(err)
        })
}
export const updateSaveTemplate = (slideData, state, setState, handleClose) => (dispatch, getState) => {
    let obj = {
        background: slideData.background,
        layers: slideData.layers
    }

    const tempData = getState().extra.savedTemplate.find(({ id }) => id === slideData.templateId)
    let objData = {
        id: tempData.id,
        data: JSON.stringify(obj),
        name: tempData.name,
        preview: "",
        thumbnail: ""
    }
    commonAxios("update-custom-template", objData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                dispatch(fetchSavedTempData())
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setState({
                ...state,
                updateLoader: false
            })
            handleClose()
        }).catch((err) => {
            setState({
                ...state,
                updateLoader: false
            })
            handleClose()
            console.log(err)
        })
}

export const generateFile = (data, setOutputData, setCropLoader) => (dispatch, getState) => {
    commonAxios("generate-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutputData(res.data);
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setCropLoader(false)
        }).catch((err) => {
            console.log(err)
            setCropLoader(false)
        })

}

export const cropVideo = (data, setOutputVideo, setOutputThumb, setCropLoader) => (dispatch, getState) => {
    commonAxios("video-crop", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutputVideo(res.data.video);
                setOutputThumb(res.data.thumbnail);
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setCropLoader(false);
        }).catch((err) => {
            console.log(err)
            setCropLoader(false);
        })
}
export const locationLayerAdd = (location, slideIndex) => (dispatch) => {
    let layer = {
        id: parseInt(Math.random() * 10000),
        type: "location",
        src: location,
        style: {
            color: "#000000",
            fontSize: 15,
            fontWeight: "",
            fontFamily: "",
            lineHeight: 1,
            textTransform: "",
            textDecoration: "",
            transform: "",
            backgroundColor: "",
            textAlign: "center",
            opacity: "",
            border: ""
        },
        position: {
            left: 0,
            top: 587,
        },
        animation: {
            name: "",
            delay: 0,
            duration: 0
        },
        isSelected: true,
        size: {
            height: 29,
            width: 360,
        },
        filter: { name: "" }
    }
    const data = { layer, slideIndex }
    dispatch({ type: "ADD_TEXT_LAYER", payload: data })
    // dispatch({type : "ADD_LOCATION_LAYER", payload : })
}

export const updateAddLocation = (loc, slideIndex, layerIndex) => (dispatch) => {
    let data = { loc, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_LOCATION", payload: data })
}

// Aduio Actions 

export const pollyLanguageFetch = (setPollyLanguages, pollyLanguages) => (dispatch, getState) => {
    let remove = ["Hindi"]
    commonAxios("get-polly-languages", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data
                arr.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });
                arr = arr.filter((curElem) => {
                    return !remove.includes(curElem.name)
                })
                setPollyLanguages({ ...pollyLanguages, data: arr });
            }
        }).catch((err) => {
            console.log(err)
        })
}
export const pollyVoiceFetch = (setPollyVoices, pollyVoices, value, setLangData, langData, setEngine) => (dispatch, getState) => {
    let formData = new FormData();
    formData.append('lang_code', value);
    commonAxios("get-artists", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setPollyVoices({ ...pollyVoices, data: res.data });
                setLangData({
                    ...langData,
                    voice: "",
                    lang: value
                })
                // setEngine(res.data[0].type)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const makeTts = (data, setGenerateData, setTtsStatus, setLoader, generateData) => (dispatch, getState) => {

    commonAxios("generate-ttsnew", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTtsStatus(false)
                setGenerateData({
                    ...generateData,
                    url: res.data.file_url,
                    duration: res.data.duration - 3
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
                setTtsStatus(true)
            }
            setLoader(false);
        }).catch((error) => {
            setTtsStatus(true)
            setLoader(false);
            console.log(error)
        })
}

export const updateTtsUrl = (val, langData, slideIndex, text) => (dispatch) => {
    let voiceOver = {
        enable: true,
        type: "mp3",
        src: val.url,
        volume: 100,
        meta: {
            text: text,
            languageId: langData.lang,
            voiceId: langData.voice,
            translateText: ""
        }
    }
    const data = { voiceOver, langData, slideIndex }
    dispatch({ type: "UPDATE_GENERATED_TTS", payload: data })
    dispatch(setAlert('Ai voice applied successfully', 'success'));
    dispatch(addSlideDuration(val.duration))
}

export const addSlideDuration = (value) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDE_DURATION', payload: value })
}

export const revertTranslate = (index) => (dispatch) => {
    dispatch({ type: "REVERT_TRANSLATE", payload: index })
}

export const updateTransLateText = (newText, index) => (dispatch) => {
    const data = { newText, index }
    dispatch({ type: "UPDATE_TEXT_TRANSLATE", payload: data })
}

export const textTranslate = (data, setTranslate, translate, slideIndex) => (dispatch, getState) => {
    commonAxios("translate", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTranslate({
                    ...translate,
                    transText: res.data.text,
                    loader: false
                })
                dispatch(updateTransLateText(res.data.text, slideIndex))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
                setTranslate({
                    ...translate,
                    loader: false
                })
            }
        }).catch((err) => {
            setTranslate({
                ...translate,
                loader: false
            })
            console.log(err)
        })
}

export const googleLanguages = (setTransLang) => (dispatch, getState) => {
    commonAxios("fetch-google-language", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let langIndex = res.data.findIndex(({ id }) => id === "40")
                res.data.splice(langIndex, 1)
                res.data.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });

                setTransLang(res.data);
            }
        }).catch((error) => {
            console.log(error)
        })
}


export const getAudio = (obj, data, setData, state, setState) => (dispatch, getState) => {
    commonAxios("load-library-audio", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(data.concat(res.data));
            }
            setState({
                ...state,
                loader: false
            })
            //  else {
            //     setError(res.data.message);
            // }

        }).catch((error) => {
            setState({
                ...state,
                loader: false
            })
            console.log(error)
        })
}

export const addBgMusic = (obj) => (dispatch) => {
    dispatch({ type: "ADD_BG_MUSIC", payload: obj })
}

export const removeBgMusic = () => (dispatch) => {
    dispatch({ type: "REMOVE_BG_MUSIC" })
}

export const getSonority = (setSonorityData, setLoader, token) => (dispatch) => {
    // axios({
    //     method: 'POST',
    //     url: 'https://regis12.amzitrage.com/fetch-all',
    //     data: {},
    //     headers: { 'Content-Type': 'application/json', 'Authorization': token }
    // }).then(res => {
    //     if (res.data.status === true) {
    //         setSonorityData(res.data.data);
    //     } else {
    //         dispatch(setAlert(res.data.message, 'danger'));
    //     }
    //     setLoader(false);
    // }).catch(err => {
    //     setLoader(false);
    // })
}

export const uploadFileData = (formData, config, setLoader, setPercent, fetchFiles) => (dispatch) => {
    commonAxios("upload-file", formData, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                if (fetchFiles) {
                    fetchFiles()
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
            setPercent(0)
        }).catch(() => {
            setLoader(false)
            setPercent(0)
        })
}

export const getUploadData = (data, setData) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
}

export const addVoiceOver = (obj, slideIndex) => (dispatch) => {
    let voiceOver = {
        enable: true,
        type: "mp3",
        src: obj.url,
        volume: 100,
        meta: {
            text: "",
            languageId: "",
            voiceId: "",
            translateText: ""
        }
    }
    const data = { voiceOver, slideIndex }
    dispatch({ type: "UPDATE_GENERATED_TTS", payload: data })
    dispatch(setAlert('Ai voice applied successfully', 'success'));
    dispatch(addSlideDuration(obj.duration))
}

export const removeVoiceOver = (slideIndex) => (dispatch) => {
    dispatch({ type: "REMOVE_VOICEOVER", payload: slideIndex })
}

export const updateLogoEnabel = (val, type) => (dispatch) => {
    if (type === "logo") {
        dispatch({ type: "UPDATE_LOGO_STATUS", payload: val })
    } else {
        dispatch({ type: "UPDATE_WATERMARK_STATUS", payload: val })
    }
}
export const updateLogoPos = (val, type) => (dispatch) => {
    if (type === "logo") {
        dispatch({ type: "UPDATE_LOGO_POS", payload: val })
    } else {
        dispatch({ type: "UPDATE_WATERMARK_POS", payload: val })
    }
}
export const updateLogo = (val, type) => (dispatch) => {
    let layer = {
        id: parseInt(Math.random() * 10000),
        type: "image",
        src: val,
        style: {
            color: "",
            fontSize: "",
            fontWeight: "",
            fontFamily: "",
            lineHeight: "",
            textTransform: "",
            textDecoration: "",
            backgroundColor: "",
            textAlign: "",
            opacity: type !== "logo" ? 0.5 : "",
            border: "",
            objectFit: ""
        },
        position: {
            left: 0,
            top: 0
        },
        animation: {
            name: "",
            delay: 0,
            duration: 0
        },
        isSelected: false,
        size: {
            height: 80,
            width: 80
        },
        filter: { name: "" }
    }
    if (type === "logo") {
        dispatch({ type: "UPDATE_LOGO", payload: layer })
    } else {
        dispatch({ type: "UPDATE_WATERMARK", payload: layer })
    }
}

export const updateDuration = (dur, slideIndex) => (dispatch) => {
    let data = { dur, slideIndex }
    dispatch({ type: "UPDATE_DURATION", payload: data })
}

export const removeLogoWater = (type) => (dispatch) => {
    if (type === "logo") {
        dispatch({ type: "REMOVE_LOGO" })

    } else {
        dispatch({ type: "REMOVE_WATERMARK" })
    }
}

export const updateAiVolume = (vol, slideIndex) => (dispatch) => {
    let data = { vol, slideIndex }
    dispatch({ type: "UPDATE_AI_VOLUME", payload: data })
}

export const updateBgVolume = (vol) => (dispatch) => {
    dispatch({ type: "UPDATE_BG_VOLUME", payload: vol })
}

export const doRendering = (data, history, setShowDelete, setButton) => (dispatch, getState) => {
    commonAxios("render-story", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTimeout(() => {
                    history.push("/dashboard")
                    dispatch(setAlert(res.msg, "success"))
                    setButton("Proceed")
                    setShowDelete(false)
                }, 5000)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setButton("Proceed")
                setShowDelete(false)
            }
        }).catch((err) => {
            console.log(err)
            setButton("Proceed")
            setShowDelete(false)
        })
}

export const addLayerLink = (link, slideIndex, layerIndex) => (dispatch) => {
    let data = { link, slideIndex, layerIndex }
    dispatch({ type: "ADD_LAYER_LINK", payload: data })
}

export const addCTA = (slideIndex) => (dispatch) => {
    let layer = {
        id: parseInt(Math.random() * 10000),
        type: "button",
        src: "BUTTON TEXT",
        style: {
            color: "#ffffff",
            fontSize: 18,
            fontWeight: "",
            fontFamily: "",
            lineHeight: 1,
            textTransform: "",
            textDecoration: "",
            transform: "",
            backgroundColor: "#000000",
            textAlign: "center",
            opacity: "",
            border: "",
            padding: 5
        },
        position: {
            left: 30,
            top: 40,
        },
        animation: {
            name: "",
            delay: 0,
            duration: 0
        },
        isSelected: true,
        size: {
            height: 60,
            width: 200,
        },
        filter: { name: "" }
    }
    let data = { layer, slideIndex }
    dispatch({ type: "ADD_CTA_LAYER", payload: data })
}

export const muteVideoAudio = (slideIndex, layerIndex) => (dispatch) => {
    let data = { slideIndex, layerIndex }
    dispatch({ type: "CHANGE_VIDEO_MUTE", payload: data })
}

export const unMuteVideoAudio = (slideIndex, layerIndex) => (dispatch) => {
    let data = { slideIndex, layerIndex }
    dispatch({ type: "CHANGE_VIDEO_UNMUTE", payload: data })
}

export const addPollLayer = (pollData, slideIndex) => (dispatch) => {
    let layer = {
        id: parseInt(Math.random() * 10000),
        type: "polls",
        src: pollData,
        style: {
            color: "#df8016",
            fontSize: 18,
            fontWeight: "",
            fontFamily: "Poly",
            lineHeight: 1,
            textTransform: "",
            textDecoration: "",
            transform: "",
            backgroundColor: "#111010",
            textAlign: "center",
            opacity: "",
            border: "",
        },
        position: {
            left: 0,
            top: 40,
        },
        animation: {
            name: "",
            delay: 0,
            duration: 0
        },
        isSelected: true,
        size: {
            height: 400,
            width: 360,
        },
        filter: { name: "" }
    }
    let data = { layer, slideIndex }
    dispatch({ type: "ADD_POLLS", payload: data })
}

export const updatePoll = (name, value, slideIndex, layerIndex) => (dispatch) => {
    let data
    if (name === "question") {
        data = { value, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_POLL_QUESTION", payload: data })
    } else if (name === "option1") {
        data = { value, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_POLL_OPTION1", payload: data })
    } else if (name === "option2") {
        data = { value, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_POLL_OPTION2", payload: data })
    } else if (name === "option3") {
        data = { value, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_POLL_OPTION3", payload: data })
    } else if (name === "option4") {
        data = { value, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_POLL_OPTION4", payload: data })
    }
}

export const fetchFonts = (setGoogleFonts, googleFonts, setFonts, fontNotSet, membership) => (dispatch) => {
    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let temp = res.data.data
            if (membership.length > 0) {
                if (membership[membership.length - 1] === "premium") {
                    temp.splice(100, temp.length - 100)
                }
            }
            temp.sort(function (a, b) {
                return a.name.localeCompare(b.name)
            });
            setFonts(temp);
            let arr = temp.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item, index) => {
                return (
                    item.value
                )
            })
            setGoogleFonts({ ...googleFonts, data: arr });
        }
    }).catch(err => {
        console.log(err)
    })
}

export const deleteUpload = (data, fetchData, state, setState, setData) => (dispatch, getState) => {
    commonAxios("delete-media-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (setData) {
                    fetchData(state.type, setData)
                }
                else {
                    fetchData()
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setState({
                ...state,
                showDelete: false,
                fileId: -1,
                button: "Delete"
            })
        }).catch((err) => {
            console.log(err)
        })
}

export const removeBgColor = (slideIndex, layerIndex) => (dispatch) => {
    let data = { slideIndex, layerIndex }
    dispatch({ type: "REMOVE_BG_COLOR_LAYER", payload: data })
}

//undo Redo

export const updatePresent = (data) => (dispatch) => {
    dispatch({ type: "SET_PRESENT", payload: data })
}
export const updateLastAction = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_LAST_ACTION", payload: data })
}

export const onUndo = (slideId) => (dispatch) => {
    dispatch({ type: "UNDO", payload: slideId })
}
export const onRedo = (slideId) => (dispatch) => {
    dispatch({ type: "REDO", payload: slideId })
}
