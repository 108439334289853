import React, { useState } from 'react'
import { useEffect } from 'react'
import closeModalx from "../../images/modal-close.png"

const Embed = ({ slideId, showModel1, setShowModel1 }) => {
    const [copy, setCopy] = useState({
        one: `<script type="text/Javascript" src="https://webstoriesbackend.storyreel.io/uploads/embed.js?storyId=${slideId}></script>`,
        stateOne: false,
        two: `<div id="story_embed_data"> </div>`,
        stateTwo: false
    })
    const handleCopy = (type) => {
        if (type === "one") {
            setCopy({
                ...copy,
                stateOne: true,
                stateTwo: false
            })
            navigator.clipboard.writeText(copy.one);
        } else if (type === "two") {
            setCopy({
                ...copy,
                stateTwo: true,
                stateOne : false
            })
            navigator.clipboard.writeText(copy.two);
        }
    }

    const handleClose = () => {
        setCopy({
            ...copy,
            stateOne: false,
            stateTwo: false
        })
        setShowModel1(false)
    }

    useEffect(() => {
        setCopy({
            ...copy,
            one: `<script type="text/Javascript" src="https://webstoriesbackend.storyreel.io/uploads/embed.js?storyId=${slideId}"></script>`,
            two: `<div id="story_embed_data"> </div>`,
        })
    }, [slideId])

    return (
        <>
            <div className={`modal VideoModal VideoModal-alt ${showModel1 ? 'show-modal' : ''} `}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{ background: "transparent" }}>
                        <div className="modal-header justify-content-end " style={{ borderBottom: "none" }}>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => handleClose()}
                                aria-label="Close">
                                <span aria-hidden="true">
                                    <img src={closeModalx} alt="" />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ background: "#000" }}>
                            <div className="row">
                                <div className="col-sm-12 mb-2 text-center">
                                    <h5 className="modal-title" style={{ width: "100%" }}>Copy Embed Code</h5>

                                </div>

                            </div>

                            <div className="form-body">
                                <form className="" method="post" >
                                    <div className='row'>
                                        <div className='col-md-12 p-4'>
                                            <div className="form-group form-group-alt" >
                                                <label className="form-text text-muted">Copy paste this code  of your HTML page</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control text-light"
                                                    style={{ height: "95px" }}
                                                    required
                                                    rows={5}
                                                    name="embed code"
                                                    placeholder="Embed code url"
                                                    value={copy.one}
                                                    readOnly
                                                />

                                            </div>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="text-right">
                                                <button type='button' className='btn btn-danger' onClick={() => handleCopy("one")}>
                                                    {copy.stateOne ? "Copied" : "Copy"}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12 p-4'>
                                            <div className="form-group form-group-alt">
                                                <label className="form-text text-muted">Copy paste this code in body section of your HTML page</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control text-light"
                                                    style={{ height: "95px" }}
                                                    required
                                                    name="title"
                                                    placeholder="Enter Title"
                                                    value={copy.two}
                                                    readOnly
                                                />
                                            </div>

                                           
                                        </div>
                                        <div className="col-md-12">
                                            <div className="text-right">
                                                <button type="button" className='btn btn-danger' onClick={() => handleCopy("two")}>
                                                {copy.stateTwo ? "Copied" : "Copy"}
                                                    </button>
                                            </div>
                                        </div>

                                    </div>


                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Embed