import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import { useDispatch } from 'react-redux';
import hexRgb from "hex-rgb";
import { useEffect } from 'react';
import { removeBgColor, updateFontBgColor, updateFontColor } from '../../../actions/storyAction';

const ColorPattern = (props) => {
    const dispatch = useDispatch()
    const colorPallets = [
        '#dee2e6',
        '#55d4a8',
        '#03a9f4',
        '#00bcd4',
        '#aa00ff',
        '#ff1744',
        '#b388ff',
        '#e040fb',
        '#ce93d8',
        '#3d5afe'
    ]
    const [color, setColor] = useState({
        enable: false,
        r: 0,
        g: 0,
        b: 0
    });

    const getHexNumbers = (color) => {
        const val = hexRgb(color)
        setColor({
            ...color,
            r: val.red,
            g: val.green,
            b: val.blue
        })
    }


    const onChangeColor = (color, index) => {
        getHexNumbers(color)
        if (props.selectedTextIndex !== -1) {
            if (props.type === "Color") {
                dispatch(updateFontColor(color, props.selectedSlideIndex, props.selectedTextIndex));
            } else {
                if (index === 0) {
                    dispatch(removeBgColor(props.selectedSlideIndex, props.selectedTextIndex));
                } else {
                    dispatch(updateFontBgColor(color, props.selectedSlideIndex, props.selectedTextIndex));
                }
            }
        }
    }

    const onInputColor = (e) => {
        let rgbaColor = e.hex !== "transparent" ? e.hex : ""
        setColor({
            ...color,
            r: e.rgb.r,
            g: e.rgb.g,
            b: e.rgb.b
        })
        if (props.selectedTextIndex !== -1) {
            if (props.type === "Color") {
                dispatch(updateFontColor(rgbaColor, props.selectedSlideIndex, props.selectedTextIndex));
            } else {
                dispatch(updateFontBgColor(rgbaColor, props.selectedSlideIndex, props.selectedTextIndex));
            }
        }
    }

    useEffect(() => {
        if (props.colorVal !== false && props.colorVal !== "" && props.colorVal && props.colorVal !== "transparent") {
            const val = hexRgb(props.colorVal)
            setColor({
                ...color,
                r: val.red,
                g: val.green,
                b: val.blue
            })
        }
        else {
            setColor({
                ...color,
                r: 0,
                g: 0,
                b: 0
            })
        }
    }, [props.colorVal])

    return (
        <div className="mt-4">
            <p className="innerHead">Font {props.type}</p>
            <div className="mt-2">
                <div className="row row-alt">
                    <div className="col-6">
                        <div className="colorpicSelect" style={{}}>

                            <div
                                style={{ background: `${props.colorVal !== "" ? props.colorVal : '#fff'}` }}
                                className="colorpicSelect-color cursor-pointer"
                                onClick={() => setColor({
                                    ...color,
                                    enable: true
                                })}
                            >

                            </div>
                            <input
                                type="text"
                                value={props.colorVal}
                                readOnly
                                className='inpField'
                            />


                        </div>
                        <p className="valTxt">HEX</p>
                        {color.enable ?
                            <>
                                <div className="swatch-cover" onClick={(e) => setColor({
                                    ...color,
                                    enable: false
                                })} />
                                <SketchPicker color={props.colorVal} onChange={(e) => onInputColor(e)} />
                            </> : null}
                    </div>
                    <div className="col-2">
                        <input
                            type="text"
                            value={color.r}
                            readOnly
                            className='inpField'
                        />
                        <p className="valTxt">R</p>
                    </div>
                    <div className="col-2">
                        <input
                            type="text"
                            value={color.g}
                            readOnly
                            className='inpField'
                        />
                        <p className="valTxt">G</p>
                    </div>
                    <div className="col-2">
                        <input
                            type="text"
                            value={color.b}
                            readOnly
                            className='inpField'
                        />
                        <p className="valTxt">B</p>
                    </div>
                </div>
            </div>
            <div className="colorSelect mt-2">
                <ul>
                    {colorPallets.map((curElem, index) => {
                        return (
                            <li key={index}>
                                <div
                                    className="coloropt-single"
                                    style={{ background: curElem }}
                                    onClick={() => onChangeColor(curElem, index)}
                                >
                                    {props.type === "Background" && index === 0 ?
                                        <div className="remove_line">

                                        </div> : ""}
                                </div>

                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default ColorPattern